import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function FeatureCard(props) {
  useEffect(() => {
    Aos.init({ duration: 300 });
  }, []);
  return (
    <div className=" my-8" data-aos="fade-up">
      <div className=" mx-auto w-8 h-8 mb-3 rounded-full bg-primary "></div>
      <h2 className="font-bold text-xl mb-2">{props.title}</h2>
      <p>{props.description}</p>
    </div>
  );
}

export default FeatureCard;
