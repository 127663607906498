import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function HiwCard(props) {
  useEffect(() => {
    Aos.init({ duration: 300 });
  }, []);
  return (
    <div className=" my-6 md:w-1/3 md:text-left " data-aos="fade-up">
      <h2 className="font-bold text-3xl mb-4">{props.title}</h2>
      <p>{props.description}</p>
    </div>
  );
}

export default HiwCard;
