import { overviewtext, overviewtitle } from "../components/texts";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import oravideo from "../assets/home.webm";
function Overview() {
  useEffect(() => {
    Aos.init({ duration: 300, once: true });
  }, []);
  return (
    <div className="p-12 mb-14 text-center md:px-40 md:py-40 gap-28 md:grid grid-cols-2 text-[#fffefe]">
      <div className="overflow-hidden rounded-3xl bg-white h-fit">
        <video
          src={oravideo}
          autoPlay
          loop
          muted
          playsInline
          className=" object-fill "
        />
      </div>
      <div className=" mt-10 md:mt-0">
        <div className="mb-8">
          <h1 data-aos="fade-up" className="text-4xl text-primary font-black">
            {overviewtitle}
          </h1>
        </div>
        <article data-aos="fade-up">{overviewtext}</article>
      </div>
    </div>
  );
}

export default Overview;
