import { Hometxt, JoinBeta } from "../components/texts";

function LandingPage() {
  return (
    <section className="text-[#fffefe] md:px-12 h-[70vh] flex justify-center items-center md:justify-start md:items-center md:h-[100vh] md:bg-hero-bg bg-contain bg-no-repeat bg-right-bottom bg-[#1d2527]">
      <div className=" md:px-12 text-center md:text-left md:w-1/2 ">
        <h1 className=" text-5xl md:text-7xl font-black mb-8">
          Experience the{" "}
          <span className="bg-gradient-to-r from-pink-500 to-violet-600 bg-clip-text text-transparent leading-normal">
            Tai Ora
          </span>
        </h1>
        <p className="mb-10">{Hometxt}</p>
        <a className=" bg-primary text-white px-5 py-2 rounded-full mt-7 ">
          {JoinBeta}
        </a>
      </div>
    </section>
  );
}

export default LandingPage;
