import Features from "./sections/feature";
import Footer from "./sections/footer";
import Header from "./sections/header";
import HowItWorks from "./sections/howitworks";
import LandingPage from "./sections/landing";
import Overview from "./sections/overview";

function App() {
  return (
    <div className="text-lg">
      <Header />
      <LandingPage />
      <Overview />
      <Features />
      <HowItWorks />
      <Footer />
    </div>
  );
}

export default App;
