import { useState } from "react";
import { JoinBeta } from "../components/texts";
import logo from "../assets/logo.svg";

function Header() {
  const [navbar, setNavbar] = useState(false);

  const changeNavBg = () => {
    if (window.scrollY >= 50) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeNavBg);

  return (
    <header
      className={
        navbar
          ? "text-[#fffefe] fixed z-50 top-0 left-0 w-full flex justify-between px-5 py-2 items-center bg-[#1d2527] transition-all duration-300 "
          : "text-[#fffefe] fixed top-0 left-0 w-full flex justify-between px-5 py-5 items-center transition-all duration-300 "
      }
    >
      <div className="flex flex-row justify-center items-center">
        <img className=" h-16 " src={logo} alt="Ora ai logo" />
      </div>
      <nav className=" hidden md:flex ">
        <ul className="flex">
          <li className=" mx-4 cursor-pointer font-semibold ">Overview</li>
          <li className=" mx-4 cursor-pointer font-semibold ">Features</li>
          <li className=" mx-4 cursor-pointer font-semibold ">How it works</li>
        </ul>
      </nav>
      <div
        className={
          navbar
            ? " bg-primary text-white p-1 px-4 rounded-full cursor-pointer transition-all duration-300 "
            : "bg-black text-white p-2 px-4 rounded-full cursor-pointer transition-all duration-300 "
        }
      >
        <a href="http://waitwhile.com/locations/taioraai">{JoinBeta}</a>
      </div>
    </header>
  );
}

export default Header;
