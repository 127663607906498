function Footer() {
  return (
    <div className=" p-10 py-4 bg-[#1d2527] text-[#fffefe]">
      <p>
        <span>&copy;</span>Copyrights. All rights reserved{" "}
        <a className=" text-primary ">Tai Ora</a>
      </p>
    </div>
  );
}

export default Footer;
